import Rest from '@/services/Rest';

/**
 * @typedef {RecoveryPasswordClub}
 */
export default class RecoveryPasswordClub extends Rest {
  /**
   * @type {String}
   */
  static resource = '/recovery-password'
}
