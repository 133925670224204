<template>
  <b-modal
    id="purchase-claim"
    name="purchase-claim"
    size="lg"
    :title="$t('views.buyer.my_purchases.text_3030')"
    @hide="resetData"
  >
    <b-row>
      <b-col class="mb-4" cols="12" md="12" data-anima="top">
        <h4 class="mb-3 green--text title-green" :class="{'heaven': isHeaven}">
          {{ $t('views.buyer.new_claim.text_083') }}
        </h4>
        <span
          class="grey--text"
          style="margin-top: 22px; padding-bottom: 12px"
        >
          {{ $t('views.buyer.new_claim.text_084') }}
        </span>
      </b-col>
      <b-col cols="12" md="12" data-anima="top">
        <b-form-group
          :label="$t('views.buyer.new_claim.text_085')"
          label-for="titulo_reclamacao"
        >
          <b-form-input
            id="titulo_reclamacao"
            name="titulo_reclamacao"
            :placeholder="$t('views.buyer.new_claim.text_086')"
            v-model="get_claim.subjective"
            maxlength="40"
            v-validate="'required'"
            type="text"
            :class="{'heaven': isHeaven}"
          ></b-form-input>
          <b-form-invalid-feedback
            :state="submit ? !errors.has('titulo_reclamacao') : null"
          >
            {{ $t('views.buyer.new_claim.text_087') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col class="mt-2" cols="12" md="12" data-anima="top">
        <b-form-group
          :label="$t('views.buyer.new_claim.text_088')"
          label-for="encaixe_problema"
        >
          <b-form-select
            v-model="get_claim.category"
            :label="$t('views.buyer.new_claim.text_089')"
            id="encaixe_problema"
            name="encaixe_problema"
            :placeholder="$t('views.buyer.new_claim.text_090')"
            v-validate="'required'"
            :options="[
              { value: 'Não recebi o produto', text: $t('views.buyer.new_claim.text_091') },
              { value: 'Não gostei do produto', text: $t('views.buyer.new_claim.text_092'), },
              { value: 'Não está em conformidade com o que foi vendido', text: $t('views.buyer.new_claim.text_093') },
              { value: 'Não me deram um bom atendimento', text: $t('views.buyer.new_claim.text_094'), },
              { value: 'Outros', text: $t('views.buyer.new_claim.text_095') },
            ]"
            :class="{'heaven': isHeaven}"
          ></b-form-select>

          <b-form-invalid-feedback
            :state="submit ? !errors.has('encaixe_problema') : null"
          >
            {{ $t('views.buyer.new_claim.text_096') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col class="mt-2" cols="12" md="12" data-anima="top">
        <b-form-group
          :label="$t('views.buyer.new_claim.text_097')"
          label-for="objetivo_reclamacao"
        >
          <b-form-select
            :placeholder="$t('views.buyer.new_claim.text_098')"
            id="objetivo_reclamacao"
            v-model="get_claim.objective"
            name="objetivo_reclamacao"
            v-validate="'required'"
            :options="optionsObjetive"
            :class="{'heaven': isHeaven}"
            :disabled="cliam_type === 'cancelamento' || cliam_type === 'reembolso'"
          ></b-form-select>
          <b-form-invalid-feedback
            :state="submit ? !errors.has('objetivo_reclamacao') : null"
          >
            {{ $t('views.buyer.new_claim.text_099') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="12" data-anima="top">
        <b-form-group
          :label="$t('views.buyer.new_claim.text_100')"
          label-for="conte_sua_historia"
        >
          <b-form-textarea
            id="conte_sua_historia"
            name="conte_sua_historia"
            :placeholder="$t('views.buyer.new_claim.text_101')"
            rows="9"
            style="min-height: 150px"
            v-model="get_claim.description"
            v-validate="'required|min:75'"
            type="text"
            :class="{'heaven': isHeaven}"
          ></b-form-textarea>
          <small>{{ $t('views.buyer.my_purchases.text_3031') }}</small>
          <b-form-invalid-feedback
            :state="submit ? !errors.has('conte_sua_historia') : null"
          >
            {{ $t('views.buyer.new_claim.text_102') }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ cancel }">
      <div class="d-flex justify-content-end">
        <div>
          <BaseButton variant="info" class="mr-4" @click="cancel">
            {{ $t('views.buyer.my_purchases.text_3033') }}
          </BaseButton>
        </div>
        <div>
          <BaseButton variant="primary" @click="onSubmit">
           {{ $t('views.buyer.my_purchases.text_3032') }}
          </BaseButton>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ClaimPublic from '@/services/resources/ClaimPublic'
const service = ClaimPublic.build()

export default {
  name: 'ModalClaim',
  props: {
    sale_id: {
      required: false,
      default: null
    },
    purchase_id: {
      required: true,
      default: -1
    },
    client_id: {
      required: true,
      default: null
    },
    cliam_type: {
      required: true,
      type: String,
      default: '',
    }
  },
  data() {
    return {
      submit: false,
      get_claim: {
        subjective: "",
      },
      optionsObjetive: [
        { value: "Quero a resolução do problema", text: this.$t('views.buyer.new_claim.text_111') },
        { value: "Quero o cancelamento e meu dinheiro de volta", text: this.$t('views.buyer.new_claim.text_112') }
      ],
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  mounted() {
    if(this.cliam_type === 'cancelamento' || this.cliam_type === 'reembolso') {
      if(this.cliam_type === 'cancelamento') this.get_claim.subjective = this.$t('views.buyer.new_claim.text_120')
      if(this.cliam_type === 'reembolso') this.get_claim.subjective = this.$t('views.buyer.new_claim.text_121')
      this.get_claim.objective = this.$t('views.buyer.new_claim.text_112')
    }
  },
  watch: {
    cliam_type() {
      if(this.cliam_type === 'cancelamento' || this.cliam_type === 'reembolso') {
        if(this.cliam_type === 'cancelamento') this.get_claim.subjective = this.$t('views.buyer.new_claim.text_120')
        if(this.cliam_type === 'reembolso') this.get_claim.subjective = this.$t('views.buyer.new_claim.text_121')
        this.get_claim.objective = this.$t('views.buyer.new_claim.text_112')
      }
    }
  },
  methods: {
    resetData() {
      this.get_claim = { subjective: ''}
      this.$emit('reset-claim-type')
    },
    cancel() {
      this.get_claim = {
        subjective: ""
      }
    },
    onSubmit() {
      this.loading = true

      this.$validator.validateAll().then((result) => {
        if (result) {
          service
            .create({
              language: this.$i18n.locale === 'en' ? 'US' : this.$i18n.locale === 'es' ? 'ES' : 'BR',
              ...this.get_claim,
              client_id: this.client_id,
              sale_id: this.cliam_type === 'cancelamento' ? this.sale_id : this.purchase_id
            })
            .then((res) => {
              this.$bvToast.toast(this.$t('views.buyer.my_purchases.text_3034'), {
                title: this.$t('views.buyer.my_purchases.text_3030'),
                variant: 'success',
                autoHideDelay: 5000,
                appendToast: true
              });
              this.$emit('update-preview')
              this.$bvModal.hide("purchase-claim")
            })
            .catch(err => console.error(err))
            .finally(() => { this.loading = false })
        } else {
          this.$bvToast.toast(this.$t('views.buyer.my_purchases.text_3035'), {
            title: this.$t('views.buyer.my_purchases.text_3030'),
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      })
    }
  }
}
</script>
