<template>
  <b-modal
    id="resend-access-club"
    name="resend-access-club"
    size="md"
    :title="$t('views.buyer.my_purchases.text_3036')"
  >

    <p>{{ $t('views.buyer.my_purchases.text_3037') }}</p>

    <template v-slot:modal-footer>
      <div class="d-flex justify-content-end">
        <div>
          <BaseButton variant="primary" class="mr-4" @click="resendAccessClub('WHATSAPP')">
            WhatsApp
          </BaseButton>
        </div>
        <div>
          <BaseButton variant="info" @click="resendAccessClub('EMAIL')">
            E-mail
          </BaseButton>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import RecoveryPasswordClub from '@/services/resources/RecoveryPasswordClub'
const service = RecoveryPasswordClub.build()

export default {
  name: 'ModalResendAccessClub',
  methods: {
    resendAccessClub(type) {
      service
        .create({type})
        .then(() => {
          this.$bvModal.hide("resend-access-club")
        })
    },
  }
}
</script>
